// Libs
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

// Components
import { device } from '../device';

// Styled
const ContainerGrid = styled.div`
  display: ${props => (props.isPatologica ? 'flex' : 'grid')};
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: ${props => (props.isPatologica ? '#ffff' : '#f4f5f7')};

  @media ${device.laptop} {
    display: block;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
`;

const Container = styled.div`
  padding: ${props =>
    props.containerWithOutPaddingBottom
      ? '4.375rem 0 0 0'
      : '4.375rem 0 4.5rem 0'};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: wrap column;
  grid-column: 2 / -2;

  @media ${device.laptop} {
    display: block;
    padding: ${props =>
      props.isPatologica
        ? props.isPaddingBottom
          ? '1.875rem 1.5rem'
          : '1.875rem 1.5rem 0'
        : '1.875rem 1.5rem 1.875rem 1.5rem'};
  }

  @media ${device.tablet} {
    padding: ${props =>
      props.isPatologica
        ? props.isPaddingBottom
          ? '1.875rem 1.5rem'
          : '1.875rem 1.5rem 0'
        : '1.875rem 1.5rem 1.875rem 1.5rem'};
  }

  ul,
  ol,
  li {
    margin: 0;
  }

  > ul {
    display: flex;
    column-gap: 50px;
    flex-direction: row;

    @media ${device.laptop} {
      column-gap: 0;
    }

    @media ${device.tablet} {
      flex-direction: column;

      font-size: 0.875rem;
    }

    > li:first-child {
      line-height: 1.625rem;

      ol {
        width: fit-content;
        list-style: none;

        li {
          display: flex;

          img {
            margin-right: 0.625rem;
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        strong {
          display: flex;
          align-items: center;
          font-size: 1rem;
        }
      }

      > ul {
        width: 33.5rem;

        @media ${device.laptop} {
          width: ${props => (props.isPatologica ? '95%' : '28rem')};
        }

        @media ${device.tablet} {
          width: 100%;
        }

        :last-child {
          @media ${device.tablet} {
            margin-bottom: 1.125rem;
          }
        }

        > li {
          margin-bottom: 3.125rem;
          margin-left: 2.1rem;
          width: 100%;

          @media ${device.tablet} {
            margin-bottom: 1.875rem;
            width: 90%;
          }
        }
      }
    }

    li:last-child {
      li {
        margin-bottom: 1.875rem;
        margin-bottom: ${props => (props.isPatologica ? '0' : '1.6rem')};

        @media ${device.mobile} {
          position: relative;
          padding-left: ${props => !props.notPaddingLeftLiMobile && '1rem'};
        }

        ::marker {
          color: transparent;
        }

        ::before {
          content: '';
          margin-right: 0.625rem;
          display: inline-block;
          width: 0.375rem;
          height: 0.375rem;
          border-radius: 50%;
          background-color: #4aa8dd;
          display: ${props => props.isPatologica && 'none'};

          @media ${device.mobile} {
            position: absolute;
            left: 0;
            top: 0.3rem;
          }
        }
      }
    }

    > li {
      display: flex;
      flex-direction: column;

      @media ${device.laptop} {
        white-space: break-spaces;
      }

      @media ${device.tablet} {
        white-space: normal;
      }

      em {
        font-style: normal;
      }

      p:first-child {
        width: ${props => (props.isPatologica ? '100%' : '55%')};

        @media ${device.tablet} {
          width: 30%;
          margin: ${props =>
            props.isPatologica ? '0 0 30px' : '0 0 1.875rem 0'};
        }

        @media ${device.mobile} {
          width: 55%;
        }

        @media ${device.mobile375} {
          width: 65%;
        }

        @media ${device.mobile320} {
          width: 75%;
        }
      }

      > p {
        margin-bottom: 3.125rem;
        > em {
          font-size: 1.5rem;

          @media ${device.tablet} {
            font-size: 1.125rem;
          }
        }
      }

      > ul {
        list-style: none;
        a {
          color: #45a7df;
        }

        :last-child {
          li img {
            @media ${device.mobile} {
              display: ${props => props.isCDIBrasilia && 'none'};
            }
          }
        }
      }
    }
  }
`;

function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

const WhyChoose = ({
  markdown,
  isCDIBrasilia,
  isPatologica,
  containerWithOutPaddingBottom,
  notPaddingLeftLiMobile,
  isPaddingBottom,
}) => (
  <ContainerGrid isPatologica={isPatologica}>
    <Container
      isCDIBrasilia={isCDIBrasilia}
      isPatologica={isPatologica}
      isPaddingBottom={isPaddingBottom}
      containerWithOutPaddingBottom={containerWithOutPaddingBottom}
      notPaddingLeftLiMobile={notPaddingLeftLiMobile}
    >
      <ReactMarkdown children={markdown} renderers={{ link: LinkRenderer }} />
    </Container>
  </ContainerGrid>
);

export default WhyChoose;
