// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import TextSection from 'components/units/unitPages/details/TextSection';
import WhyChooseOurCDI from 'components/cdi/WhyChooseOurCDI';
import MainServices from 'components/promptService/mainServices';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';
import Qa from 'components/Qa';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 0px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media ${device.laptop} {
      margin-bottom: 0;
      align-items: center;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: none;
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const GridMainServices = styled.div`
  margin: 0;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    margin-top: 50px;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 9px;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, pageContext) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part
          gridColumn="2 / -2"
          width="100%"
          isDisplay="flex"
          notPaddingTop
          paddingBottom
          isPaddingTop
          paddingTop
        >
          <TextSection
            markdown={getMarkdown(page, 'ckrff3wj45mfv0a71b2xsdesq', true)}
            isAbout
            isShowing
            isCDICardio
            isPatologica
            isBackground
            isOthersUnits
            containerWidth="50%"
            noMarginRightPage
            colorText
          />
          <TextSection
            markdown={getMarkdown(page, 'ckrffdw3k5wur0b72sgxo2d33', true)}
            fragments={getMarkdown(page, 'ckrffdw3k5wur0b72sgxo2d33')}
            isAbout
            isShowing
            isPatologica
            marginLeftContainerMobile="3%"
            containerColorEmDesk="#231f20"
            isAddressAndTimes
            isIncreasingContainerWidth
            containerWidth="49%"
          />
        </Part>
        <GridMainServices>
          <Part gridColumn={'2 / -2'}>
            <MainServices
              markdown={getMarkdown(page, 'ckrff6miw5mkd0d8054dtenmt', true)}
              isPatologica
              infusion
              liMarginBottomDesk="2.8rem"
            />
          </Part>
        </GridMainServices>
        <Part gridColumn={'2 / -2'}>
          <WhyChooseOurCDI
            markdown={getMarkdown(page, 'ckrgfhpb42tak0b20zr4cp9my', true)}
            fragments={getMarkdown(page, 'ckrgfhpb42tak0b20zr4cp9my')}
            isCDIBrasilia
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderTop borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgg1z8w36i30c65d24di8pi', true)}
            fragments={getMarkdown(page, 'ckrgg1z8w36i30c65d24di8pi')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isPatologica
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgg534o38ug0c75r9doax7v', true)}
            fragments={getMarkdown(page, 'ckrgg534o38ug0c75r9doax7v')}
            breakLineBr
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgga8bc3ehw0c75d0uehfaz', true)}
            fragments={getMarkdown(page, 'ckrgga8bc3ehw0c75d0uehfaz')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgge1ns3feq0c75purfvkod', true)}
            fragments={getMarkdown(page, 'ckrgge1ns3feq0c75purfvkod')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'cks67i9n4ckq80c75fcia5ns1', true)}
            fragments={getMarkdown(page, 'cks67i9n4ckq80c75fcia5ns1')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrggj2zk3ly30c75qvejbnza', true)}
            fragments={getMarkdown(page, 'ckrggj2zk3ly30c75qvejbnza')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgglszc3n7u0c75vkcfcm7q', true)}
            fragments={getMarkdown(page, 'ckrgglszc3n7u0c75vkcfcm7q')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrggnsqo3nkn0c752f7jk3sd', true)}
            fragments={getMarkdown(page, 'ckrggnsqo3nkn0c752f7jk3sd')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrggpmbs3po50c65ziduk49q', true)}
            fragments={getMarkdown(page, 'ckrggpmbs3po50c65ziduk49q')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgh4fi842um0b200r3av660', true)}
            fragments={getMarkdown(page, 'ckrgh4fi842um0b200r3av660')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgh5wqw41c00e76incujt8d', true)}
            fragments={getMarkdown(page, 'ckrgh5wqw41c00e76incujt8d')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgh7rvk42or0c65v2jlrrzr', true)}
            fragments={getMarkdown(page, 'ckrgh7rvk42or0c65v2jlrrzr')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isExam
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckrgh9m8g468t0c650vhfdvj1', true)}
            fragments={getMarkdown(page, 'ckrgh9m8g468t0c650vhfdvj1')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            isFragmentTitle
            prontoAtendimento
            isFacilities
            isPatologica
          />
        </Part>
        <Part gridColumn={'1 / -1'} isNotBorder isMarginFullWidth>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
        {page?.[0]?.qa && (
          <Part gridColumn={'2 / -2'} borderTop>
            <Qa
              title={'Perguntas Frequentes'}
              data={page?.[0]?.qa}
              noBorderBottomLastElement
            />
          </Part>
        )}
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding borderTop>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const AnatomiaPatologica = ({ page, pageContext }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckrff21eg5lqn0a71z5yb93pw', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, pageContext)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckrgj8o3s58yk0c75thf84y5q" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_HighlightContent {
                    id
                    name
                    type
                    backgroundColorInitial {
                      hex
                    }
                    backgroundColorFinal {
                      hex
                    }
                    title
                    customTitle
                    description
                    images {
                      handle
                      width
                      height
                      url
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                  ... on GraphCMS_DoctorAndSpecialtyContent {
                    id
                    name
                    type
                    title
                    subtitle
                    estado
                    images {
                      handle
                      width
                      height
                      url
                    }
                    specialties {
                      id
                      name
                      slug
                      customUrl
                      unity
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors(first: 1000) {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <AnatomiaPatologica page={response.gcms.site.pages} {...props} />
        );
      }}
    />
  );
};
